/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';

import 'styled-components/macro';
import { useMediaPredicate } from 'react-media-hook';
import { StyledTableHeader, TotalResult, ResultPerPage, StyledSelect } from './TableHeader.styles';

function TableHeader({ exportBtn, total, resultPerPage, setPageSize, page, refresh, details, isSearchBar }) {
  const MinWidth992 = useMediaPredicate('(min-width: 992px)');
  const resultPerPageOptions = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];

  return (
    <StyledTableHeader>
      {isSearchBar && (
        <TotalResult>
          {MinWidth992 && 'Showing'} {page * resultPerPage - resultPerPage + 1} -{' '}
          {total <= resultPerPage ? total : page * resultPerPage} of {total} results
        </TotalResult>
      )}
      {isSearchBar && (
        <ResultPerPage>
          <span css="margin-right: 30px;"> {details ?? null}</span>
          {refresh ?? null}
          {exportBtn ?? null}
          <span css="margin-left: 15px;">Result per page: </span>
          <StyledSelect
            onChange={({
              target: {
                value: { value },
              },
            }) => setPageSize(value)}
            isSearchable={false}
            options={resultPerPageOptions}
            defaultValue={resultPerPageOptions[0]}
            sm
            noMargin
            css="padding:0;"
          />
        </ResultPerPage>
      )}
    </StyledTableHeader>
  );
}

export default TableHeader;

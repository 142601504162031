import React from 'react';
import Pagination from '../../molecules/Pagination';
import TableHeader from '../TableHeader';
import Filters from '../../../common/filters';
import { StyledTableLayout } from './TableLayout.styles';

function TableLayout({
  children,
  currentPage = 1,
  pageSize = 10,
  totalCount = 0,
  onChangeFilters,
  customFilterKey = '',
  exportBtn,
  extraFilters,
  filters = true,
  noNegativeMargin,
  refresh,
  details,
  isSearchBar = true,
}) {
  return (
    <>
      {filters && isSearchBar && (
        <Filters
          onChangeFilters={_ => onChangeFilters({ ..._, page: 1 })}
          customFilterKey={customFilterKey}
          extraFilters={extraFilters}
        />
      )}
      <StyledTableLayout noNegativeMargin={noNegativeMargin}>
        <TableHeader
          isSearchBar={isSearchBar}
          total={totalCount}
          page={currentPage}
          resultPerPage={pageSize}
          setPageSize={_ => onChangeFilters({ pageSize: _, page: 1 })}
          exportBtn={exportBtn}
          refresh={refresh}
          details={details}
        />
        {children}
        {isSearchBar && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={_ => onChangeFilters({ page: _ })}
          />
        )}
      </StyledTableLayout>
    </>
  );
}

export default TableLayout;

import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import { useContextHook } from 'use-context-hook';
import Tooltip from 'components/atoms/Tooltip';
import Modal from 'components/molecules/Modal';
import QrModal from 'components/molecules/QrModal';
import LoginTemplate from '../../templates/LoginTemplate';
import Field from '../../molecules/Field';
import Form, { useForm } from '../../molecules/Form';
import { StyledForm } from './Login.styles';

function Login() {
  const [form] = useForm();
  const { onLogin, user_loading, authOtp, showTokenModal, setShowTokenModal } = useContextHook(AuthContext, [
    'onLogin',
    'user_loading',
    'authOtp',
    'showTokenModal',
    'setShowTokenModal',
  ]);
  const onClose = () => {
    window.location.href = '/';
  };
  return (
    <LoginTemplate title="Sign In" subtitle="Hello there! Sign in and start managing user accounts.">
      <StyledForm form={form} onSubmit={onLogin}>
        <Form.Item
          type="text"
          label="Email"
          name="email"
          placeholder="Your Email"
          prefix={<i className="material-icons-outlined">email</i>}
          rules={[{ required: true }, { pattern: /^.{0,256}$/, message: 'Maximum Character Length is 256' }]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="password"
          label="Password"
          name="password"
          placeholder="Your Password"
          prefix={<i className="material-icons-outlined">lock</i>}
          rules={[
            {
              required: true,
            },
            { pattern: /^.{8,64}$/, message: 'Minimum Character Length is 8 and Maximum Character Length is 64' },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="Code"
          name="token"
          placeholder="Enter the code"
          labelIcon={
            <Tooltip
              width={200}
              title="If you don't have a code not to worry Enter your email and password and click login to move through the process."
              type="dark">
              <i className="material-icons-outlined">help_outline</i>
            </Tooltip>
          }
          rules={[{ pattern: /^.{6}$/, message: 'Maximum Character Length is 6' }]}>
          <Field />
        </Form.Item>
        <Button loading={user_loading} type="primary" htmlType="submit" width={150} css="margin: 0 auto;">
          Sign in
        </Button>
      </StyledForm>
      <Modal isOpen={showTokenModal} setIsOpen={setShowTokenModal} onClose={onClose}>
        <QrModal qrImg={authOtp} />
      </Modal>
    </LoginTemplate>
  );
}

export default Login;

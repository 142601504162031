import styled from 'styled-components/macro';

export const StatementStyled = styled.section`
  background: #f5faff;
  padding: 25px 10px;
  border-radius: 20px;

  @media (min-width: 768px) {
    padding: 40px 25px;
  }
  @media (min-width: 1200px) {
    padding: 50px;
  }

  .modal-head {
    margin-bottom: 10px;

    /* @media (min-width: 1200px) { */
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;
    /* } */

    .logo-title-holder {
      max-width: 100%;
      margin: 0 0 20px;

      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        gap: 20px;
      }
      @media (min-width: 1200px) {
        max-width: 670px;
        gap: 40px;
        margin: 0;
      }

      .logo {
        width: 120px;
        flex-shrink: 0;
        margin: 0 auto 15px;

        @media (min-width: 768px) {
          margin: 0;
        }
        @media (min-width: 1200px) {
          width: 190px;
        }

        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
      }

      .heading {
        display: block;
        font-size: 25px;
        line-height: 30px;
        font-weight: 700;
        color: var(--dark);
        text-align: center;

        @media (min-width: 768px) {
          font-size: 32px;
          line-height: 38px;
          text-align: left;
        }
      }
    }

    .heading-holder {
      margin: 0;
    }
  }

  .info-col {
    max-width: 500px;

    @media (min-width: 768px) {
      display: flex;
      gap: 15px;
    }
    @media (min-width: 1200px) {
      max-width: 100%;
      gap: 40px;
    }
  }

  .col {
    width: 100%;
    max-width: 250px;
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 10px;

    @media (min-width: 1200px) {
      margin-bottom: 24px;
    }

    .image-holder {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e9eef3;
      border-radius: 30px;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .title {
      display: block;
      color: var(--dark);
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  .paragraph {
    width: 100%;
    margin: 0 0 20px;
  }

  .table-wrap {
    overflow: hidden;
    margin: 0 0 20px;
    border-radius: 20px;
    background: var(--white);

    .tables-heading {
      display: block;
      font-size: 20px;
      line-height: 24px;
      margin: 20px 20px 0px;
    }
  }

  .grand-total {
    background: var(--white);
    border-radius: 20px;
    padding: 20px 15px;
    margin-top: 24px;
    overflow: hidden;

    @media (min-width: 768px) {
      padding: 23px 24px;
    }
  }

  .grand-total {
    .field {
      padding-bottom: 15px;

      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 26px;
      }

      &:nth-last-child(1) {
        padding-bottom: 0;
        padding-top: 15px;

        @media (min-width: 768px) {
          padding-top: 30px;
        }
      }
      &:nth-child(2) {
        border-bottom: 1px solid var(--border-color);
      }
      .text {
        display: block;
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        margin: 0 0 5px;

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 24px;
          font-weight: 400;
        }
      }
      .total {
        display: block;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        color: var(--primary);

        @media (min-width: 768px) {
          display: inline-block;
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }
`;

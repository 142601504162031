/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
const transactionService = {
  _url: process.env.REACT_APP_MERCHANT_TOOL_API,

  GetReportDetails(id) {
    const [report, setReport] = useState({});
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.viewReport(id))
        .then(res => {
          setReport(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [id]);
    return {
      report_loading: status === STATUS.LOADING,
      report_error: status === STATUS.ERROR ? status : '',
      report_data: report,
    };
  },

  async viewReport(id) {
    const res = await Fetch.get(`${this._url}/statement/${id}`);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};
export default transactionService;
